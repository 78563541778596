<h3>
  Education
</h3>

<ul>
  <li>
    <p>
      B.S. Computer Science with Minor in Mathematics
    </p>
    <p class="subtext">
      December 2018 | Purdue, Indianapolis
    </p>
  </li>
  <li>
    <p>
        M.S. Computer Science
    </p>
    <p class="subtext">
        December 2019 | Purdue, Indianapolis
    </p>
  </li>
  <li>
    <p>
        PhD Computer Science
    </p>
    <p class="subtext">
        <span class="italic">In progress</span> | Purdue, Indianapolis
    </p>
    <p class="subtext">
        Advisor: Dr. Mihran Tuceryan
    </p>
  </li>
</ul>