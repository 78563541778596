
<div class="tutorial-option">
  <a routerLink="/tutorials/moss">
    <h4>
      MOSS Tutorial
    </h4>
    <p class="italic">
      MOSS is a document-fingerprinting and matching program developed by Stanford University. For more 
      official information on...
    </p>
  </a>
</div>
