import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { MossTutorialComponent } from './moss-tutorial/moss-tutorial.component';
import { ResumeComponent } from './resume/resume.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'tutorials', component: TutorialsComponent },
  { path: 'tutorials/moss', component: MossTutorialComponent },
  { path: 'resume', component: ResumeComponent}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    useHash: true,
  }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
