import { Component } from '@angular/core';
import * as ace from '../assets/js/ace-src/ace.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'v01';
}
