<div id="ace-editor-{{eid}}-container">
    <div class="editor-header">
      <h4>{{title}}</h4>
    </div>
    <div id="ace-editor-{{eid}}"></div>
</div>
<div class="space"></div>

<!--

  SAMPLE CODE:

  <app-code-editor 
  [eid]="'0'"
  [title]="'mossPrep.py'"
  [height]="'700px'" 
  [width]="'880px'" 
  [file]="'assets/mossPrep230.py'"
  [readOnly]="true"
  [theme]="'ambiance'"></app-code-editor>

-->