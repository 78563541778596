import { Component, OnInit, Input } from '@angular/core';
import { FileLoaderService } from '../file-loader.service';

declare var ace: any;

@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss']
})
export class CodeEditorComponent implements OnInit {

  @Input() file: string;
  @Input() height: string;
  @Input() width: string;
  @Input() title: string;
  payload: string;
  editor: any;
  @Input() eid: string;
  @Input() lang: string;
  @Input() theme: string;
  @Input() readOnly: boolean;
  idPrefix: string = "ace-editor-";

  constructor(private fileLoaderService: FileLoaderService) { }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.fileLoaderService.loadFile(this.file).subscribe(
      data => { 
        this.payload = data;

        let div = document.getElementById(this.idPrefix + this.eid);
        let outer = document.getElementById(this.idPrefix + this.eid + "-container");
        div.innerHTML = this.payload;

        this.editor = ace.edit(this.idPrefix + this.eid);
        outer.style.height = this.height;
        outer.style.width = this.width;
        div.style.height = '100%';
        div.style.width = '100%';
        this.editor.session.setMode('ace/mode/' + (this.lang || 'python'));
        this.editor.setTheme('ace/theme/' + (this.theme || 'ambiance'));
        this.editor.setReadOnly(this.readOnly || false);
        this.editor.setHighlightActiveLine(!this.editor.getReadOnly());
        this.editor.renderer.setShowPrintMargin(false);
      }
    );
    
  }

}
