import { Component, OnInit } from '@angular/core';


declare var ace: any;

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {

  editor;

  constructor() { }

  ngOnInit() {
    // this.editor = ace.edit("editor");
    // this.editor.setTheme("ace/theme/monokai");
    // this.editor.session.setMode("ace/mode/javascript");
    // this.editor.setReadOnly(true);
  }

}
