<div id="outer">
  <div id="banner">
    <span id="header-container" routerLink="/">
      <h1 class="heading">
        Blake Troutman
      </h1>
      <h3 class="subheading">
        Software Engineer
      </h3>
    </span>
  </div>

  <div id="nav-container">
    <nav id="nav-bar">
      <a routerLink="/">
        <div class="nav-option">
          <h4>Home</h4>
        </div>
      </a>
      <a routerLink="/tutorials">
        <div class="nav-option">
          <h4>Tutorials</h4>
        </div>
      </a>
      <!-- <a>
        <div class="nav-option">
          <h4>Projects</h4>
        </div>
      </a> -->
      <a href="https://github.com/batroutman/">
        <div class="nav-option">
          <h4>GitHub</h4>
        </div>
      </a>
    </nav>
  </div>

  <div id="inner">

  <router-outlet></router-outlet>

  </div>
</div>

