<div class="content-block">
  <h3>
    Welcome Websurfer
  </h3>
  <p>
    Hello, I'm Blake Troutman and this is my website. I am a Ph.D. student at the Purdue School of Science at Indiana 
    University—Purdue University Indianapolis (IUPUI). I study augmented reality under the tutelage of Dr. Mihran Tuceryan. 
    Specifically, my research interests revolve around real-time, robust, optical tracking on mobile hardware such as 
    smartphones and tablets. Those of you who are interested in somewhat-recent developments in the virtual reality market 
    may understand how the release of the Oculus Quest and Oculus Rift S have sparked my interest in this research domain. 
    I also have a keen enjoyment for web development, which is why I have this site in the first place. I have been 
    learning to make websites since I was in middle school and consequentially developed enough proficiency in it to 
    work as a web developer at Indiana Farm Bureau Insurance for over a year. My industrial experience has exposed me 
    to fancy web technologies, such as Angular, RxJS, REST, and many others. I use this site to tinker with new technology 
    I find interesting, publish tutorials that my peers may find useful, and share projects I've been working on via my 
    GitHub account. For first-time users of the World Wide Web, start clicking buttons in the navigation bar to get started.
  </p>
</div>